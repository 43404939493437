import {Component, Vue, Watch} from 'vue-property-decorator';
import {evalApi} from "../../plugins/axios";
import {DataOptions} from "vuetify";
import {SurveyFundingPercent} from "../../shared/model/SurveyFundingPercent";
import {rules} from "@/shared/model/Rules";


@Component({
    components: {},
})
export default class SurveyFundingPercentList extends Vue {
    maskText = "";
    search = "";
    valid = true;
    menu1 = false
    menu2 = false
    surveyFundingPercentSearch: SurveyFundingPercent = new SurveyFundingPercent()
    requiredRule = rules;
    snackbar = {
        visible: false,
        color: "success",
        text: ""
    };

    public surveyFundingPercentList: Array<SurveyFundingPercent> = new Array<SurveyFundingPercent>();
    public options: DataOptions = {
        itemsPerPage: 15,
        page: 1,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false
    }
    public totalElements: any = 0
    public dialog = false
    public confirmdelete = false
    public isLoading = true
    public srIsLoading = true
    public editedIndex = -1;
    public editedItem = {
        surveyFundingPercentId: "",
        fundingTypeId: "",
        surveyTypeId:"",
        federalFiscalYear : "",
        percent : "",
        budgetDepartment:"",
        startDate: "",
        endDate: ""
    }
    public defaultItem = {
        surveyFundingPercentId: "",
        fundingTypeId: "",
        surveyTypeId:"",
        federalFiscalYear : "",
        percent : "",
        budgetDepartment:"",
        startDate: "",
        endDate: ""
    }
    public srCountdown = 4;

    public created() {
        this.loadTable()
    }

    public mounted() {
        const self = this
        document.addEventListener('keypress', function(ev) {
            if (ev.key === 'Enter' && self.$route.name === "SurveyFundingPercentList") {
                self.loadTable()
            }
        })
        this.srOnlyLoad()
    }

    @Watch("options",
        {deep: true}
    )
    public loadTable(): void {
        evalApi.post(`/surveyFundingPercent/search`, {
            surveyFundingPercentId: this.surveyFundingPercentSearch.surveyFundingPercentId,
            fundingTypeId: this.surveyFundingPercentSearch.fundingTypeId,
            surveyTypeId: this.surveyFundingPercentSearch.surveyTypeId,
            federalFiscalYear : this.surveyFundingPercentSearch.federalFiscalYear,
            percent : this.surveyFundingPercentSearch.percent,
            budgetDepartment: this.surveyFundingPercentSearch.budgetDepartment,
            startDate: this.surveyFundingPercentSearch.startDate,
            endDate: this.surveyFundingPercentSearch.endDate,
            page: this.options.page,
            size: this.options.itemsPerPage,
        }).then((res: any) => {
            if (res.data.content != null) {
                for (let i = 0; i < res.data.content.length; i++) {
                    if(res.data.content[i].endDate != null) {
                        res.data.content[i].endDate = new Date(res.data.content[i].endDate).toISOString().split('T')[0]
                    }
                    if(res.data.content[i].startDate != null) {
                        res.data.content[i].startDate = new Date(res.data.content[i].startDate).toISOString().split('T')[0]
                    }
                }
            }
            this.surveyFundingPercentList = res.data.content;
            this.totalElements = res.data.totalSize;
            this.isLoading = false;
        })
    }

    public srOnlyLoad(): void {
        if(this.isLoading && this.srCountdown > 0){
            setTimeout(() => {
                this.srCountdown -= 1
                this.srOnlyLoad()
            }, 1000)

        }
        else if(this.isLoading) {
            this.srCountdown = 4
            this.srIsLoading = !this.srIsLoading
            this.srOnlyLoad()
        }
        else {
            this.srIsLoading = false
        }
    }

    public editItem(item: any) {
        this.editedIndex = this.surveyFundingPercentList.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
    }

    public save(): void {
        evalApi.post("/surveyFundingPercent/update", {
            surveyFundingPercentId: this.editedItem.surveyFundingPercentId,
            fundingTypeId: this.editedItem.fundingTypeId,
            surveyTypeId: this.editedItem.surveyTypeId,
            percent: this.editedItem.percent,
            budgetDepartment: this.editedItem.budgetDepartment,
            federalFiscalYear: this.editedItem.federalFiscalYear,
            startDate: this.editedItem.startDate,
            endDate: this.editedItem.endDate,
            //TODO: insert date should not be required here (make payload similar to creating an evaluation record - for all services)
            insertUid: "hrdtimetracking_owner",
            insertDate: new Date()
        }).then(res => {
            this.snackbar.color = "success"
            this.snackbar.text = "Survey funding percent type " + this.editedItem.surveyFundingPercentId + " has been updated."
            this.snackbar.visible = true
            this.loadTable()
        }).catch(error => {
            this.snackbar.color = "error"
            this.snackbar.text = "An error occurred updating the survey funding percent type: >>>> " + error
            this.snackbar.visible = true
        })
        this.close()
    }

    public close(): void {
        this.dialog = false
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
    }

    public closeDelete(): void {
        this.confirmdelete = false
        console.log("closed the delete confirm box")
    }

    public headers = [
        {text: "Actions", value: "actions", sortable: false},
        {text: "Survey Funding Percent ID", value: "surveyFundingPercentId", sortable: false},
        {text: "Funding Type ID", value: "fundingTypeId", sortable: false},
        {text: "Survey Type ID", value: "surveyTypeId", sortable: false},
        {text: "Federal Fiscal Year ", value: "federalFiscalYear", sortable: false},
        {text: "Budget Department", value: "budgetDepartment", sortable: false},
        {text: "Percent", value: "percent", sortable: false},
        {text: "Start Date", value: "startDate", sortable: false},
        {text: "End Date", value: "endDate", sortable: false}
    ];

}